// IMPORT DEPENDENCIES
import {BASE_URL, UPLOAD_BASE_URL} from '../../urls/base/base'

export const CREATE_BRAND_URL = `${BASE_URL}/admin/createbrand`;
export const GET_BRAND_LIST_URL = `${BASE_URL}/admin/brandlist`;
export const ADD_PRODUCT_URL = `${BASE_URL}/admin/addproduct`;
export const GET_PRODUCT_LIST_URL = `${BASE_URL}/admin/productlist`;
export const ADD_BANIERE_URL = `${BASE_URL}/admin/addbaniere`;
export const GET_BANIERE_LIST_URL = `${BASE_URL}/admin/banierelist`;
export const CHANGE_BANIERE_STATUS_URL = `${BASE_URL}/admin/changestatus`;
export const ADD_PUB_URL = `${BASE_URL}/admin/addpub`;
export const GET_PUB_LIST_URL = `${BASE_URL}/admin/publist`;
export const CHANGE_PUB_STATUS_URL = `${BASE_URL}/admin/changepubstatus`;
export const CHANGE_SHOW_STATUS_URL = `${BASE_URL}/admin/changeshowstatus`;
export const CHANGE_POPULAIRE_STATUS_URL = `${BASE_URL}/admin/changepopulairestatus`;
export const CHANGE_PRODUCT_POPULAIRE_STATUS_URL = `${BASE_URL}/admin/changeproductpopulairestatus`;
export const CHANGE_PRODUCT_PLUSREGARDES_STATUS_URL = `${BASE_URL}/admin/changeproductplusregardesstatus`;
export const CHANGE_PRODUCT_NOUVEAUTES_STATUS_URL = `${BASE_URL}/admin/changeproductnouveautestatus`;
export const CHANGE_PRODUCT_SHOW_STATUS_URL = `${BASE_URL}/admin/changeproductshowstatus`;

export const UPLOAD_FILE_URL = `${UPLOAD_BASE_URL}/yatouu/uploadfile`;