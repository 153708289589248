import React, { useState } from "react";
import Dropzone from 'react-dropzone'
import Checkbox from "react-custom-checkbox";
import {useDispatch, useSelector} from "react-redux";
import {uploadFileService, createBrandService} from "../../actions/home/home"
import {Redirect} from "react-router-dom";

import Navbar from "../../components/navbar/navbar";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Loader from "../../components/loader/loader";

const CreateMarque = () => {

    const Dispatch  = useDispatch();

    const loadingupload = useSelector(state => state.HomeReducer.loadingupload);
    const filename = useSelector(state => state.HomeReducer.filename);
    const loading = useSelector(state => state.HomeReducer.loading);
    const createbrandstatus = useSelector(state => state.HomeReducer.createbrandstatus);
    const token = useSelector(state => state.AuthReducer.token);

    const [nom, SetNom] = useState("");
    const [nomProprietaire, setNomProprietaire] = useState("");
    const [telephone, setTelephone] = useState("");
    const [description, setDescription] = useState("");
    const [type, setType] = useState("0");

    const [error, setError] = useState(false);

    const handleFile = (file) => {
        console.log("filesss", file[0])
        Dispatch(uploadFileService(file[0]))
    }

    const handleNom = (e) => {
        SetNom(e.target.value)
        setError(false)
    }

    const handleNomProprietaire = (e) => {
        setNomProprietaire(e.target.value)
        setError(false)
    }

    const handleDescripton = (e) => {
        setDescription(e.target.value)
        setError(false)
    }


    const handleTelephone = (e) => {
        setTelephone(e.target.value)
        setError(false)
    }

    const handleType = (e) => {
       setType(e.target.value)
       setError(false)
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        if(nom !=="" || nom.trim() !== "" ||  nomProprietaire !=="" || nomProprietaire.trim() !== "" || description !=="" || description.trim() !== ""  || telephone !=="" || telephone.trim() !== "" || type !=="0" || filename!=="" )
        {
          Dispatch(createBrandService(nom, nomProprietaire, telephone, description, Number(type) , filename, token))
        }
        else{
            setError(true)
        }
    }

    let redirect = null;

    if(createbrandstatus){
        redirect = <Redirect to="/brand-list"/>
    }

    return (
        <>
            <div id="wrapper">
                {redirect}
                <Header />
                <Navbar />
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card-box">
                                        <h4 class="m-t-0 header-title">Creer une marque</h4>
                                       
                                        <form onSubmit={handleSubmit}>
                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                    <label for="inputEmail4" class="col-form-label">Nom de la marque</label>
                                                    <input type="text" value={nom} onChange={handleNom} class="form-control" id="inputEmail4" placeholder="Nom de la marque" />
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="inputPassword4" class="col-form-label">Nom du proprietaire</label>
                                                    <input type="text" value={nomProprietaire} onChange={handleNomProprietaire} class="form-control"  placeholder="Nom du proprietaire" />
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="inputAddress" class="col-form-label">Telephone</label>
                                                <input type="text" value={telephone} onChange={handleTelephone} class="form-control"  placeholder="Telephone" />
                                            </div>
                                            <div class="form-group">
                                                <label for="inputAddress2" class="col-form-label">Description</label>
                                                <input type="text" value={description} onChange={handleDescripton} class="form-control" placeholder="Description" />
                                            </div>

                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                    <label for="inputState" class="col-form-label">Type</label>
                                                    <select value={type} onChange={handleType} class="form-control">
                                                        <option value="0">Choisir</option>
                                                        <option value="1">Hommes</option>
                                                        <option value="2">Femmes</option>
                                                        <option value="3">Enfants</option>
                                                        <option value="4">Accessoires</option>
                                                        <option value="5">Tout</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <Dropzone
                                                onDrop={acceptedFiles => handleFile(acceptedFiles)}
                                                accept='image/jpg,image/jpeg,image/png'
                                            >
                                                {({ getRootProps, getInputProps, isDragActive }) => (   
                                                    <section>
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            <p style={{
                                                                background: "#f5f5f5",
                                                                border: isDragActive ? "1px dashed #71b6f9" : "1px dashed #c2c2c2",
                                                                borderRadius: "3px",
                                                                textAlign: "center",
                                                                padding: "36px",
                                                                fontSize: "12px",
                                                                fontWeight: "600"
                                                            }}> 

                                                            {isDragActive ? "Uploaded" : loadingupload ? "Chargement":  filename!=="" ? filename : "Glissez ou cliquer pour importer le logo" }
                                                                                              
                                                             </p>
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>

                                            {error? <p style={{color:"red"}}>Verifiez vos informations</p> : null}
                                            {
                                                loading ? <Loader/> :    <button type="submit" class="btn btn-primary" style={{ marginTop: 10 }} >Creer</button>
                                            }
                                         
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
};
export default CreateMarque;