import React from "react";

import Navbar from "../../components/navbar/navbar";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

const Panel = () => {
    return (
        <>
            <div id="wrapper">
                <Header />
                <Navbar />
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <div className="row">

                                <div className="col-xl-3 col-md-6">
                                    <div className="card-box">
                                        <div className="dropdown float-right">
                                            <a href="#" className="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false">
                                                <i className="mdi mdi-dots-vertical"></i>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <a href="javascript:void(0);" className="dropdown-item">Liste</a>
                                                <a href="javascript:void(0);" className="dropdown-item">Ajouter une compagnie</a>
                                                <a href="javascript:void(0);" className="dropdown-item">Raffraichir</a>
                                            </div>
                                        </div>
                                        <h4 className="header-title mt-0 mb-3">Marques</h4>
                                        <div className="widget-box-2">
                                            <div className="widget-detail-2 text-right">
                                                <span className="badge badge-warning badge-pill float-left mt-3">32% <i className="mdi mdi-trending-up"></i> </span>
                                                <h2 className="font-weight-normal mb-1"> 0 </h2>
                                                <p className="text-muted mb-3">marques enregistrees</p>
                                            </div>
                                            <div className="progress progress-bar-alt-success progress-sm">
                                                <div className="progress-bar bg-warning" role="progressbar"
                                                    aria-valuenow="77" aria-valuemin="0" aria-valuemax="100"
                                                    style={{ width: "77%" }}>
                                                    <span className="sr-only">77% Complete</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6">
                                    <div className="card-box">
                                        <div className="dropdown float-right">
                                            <a href="#" className="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false">
                                                <i className="mdi mdi-dots-vertical"></i>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <a href="javascript:void(0);" className="dropdown-item">Mes messages</a>
                                                <a href="javascript:void(0);" className="dropdown-item">Envoyer un message</a>
                                                <a href="javascript:void(0);" className="dropdown-item">Raffraichir</a>
                                            </div>
                                        </div>

                                        <h4 className="header-title mt-0 mb-3">Produits(Articles)</h4>
                                        <div className="widget-box-2">
                                            <div className="widget-detail-2 text-right">
                                                <span className="badge badge-success badge-pill float-left mt-3">32% <i className="mdi mdi-trending-up"></i> </span>
                                                <h2 className="font-weight-normal mb-1"> 0 </h2>
                                                <p className="text-muted mb-3">Articles</p>
                                            </div>
                                            <div className="progress progress-bar-alt-success progress-sm">
                                                <div className="progress-bar bg-success" role="progressbar"
                                                    aria-valuenow="77" aria-valuemin="0" aria-valuemax="100"
                                                    style={{ width: "77%" }}>
                                                    <span className="sr-only">77% Complete</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-xl-3 col-md-6">
                                    <div className="card-box">
                                        <div className="dropdown float-right">
                                            <a href="#" className="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false">
                                                <i className="mdi mdi-dots-vertical"></i>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <a href="javascript:void(0);" className="dropdown-item">Liste des paiments</a>
                                                <a href="javascript:void(0);" className="dropdown-item">Creer un paiment</a>
                                                <a href="javascript:void(0);" className="dropdown-item">Raffraichir</a>
                                            </div>
                                        </div>

                                        <h4 className="header-title mt-0 mb-3">Commandes</h4>

                                        <div className="widget-box-2">
                                            <div className="widget-detail-2 text-right">
                                                <span className="badge badge-danger badge-pill float-left mt-3">32% <i className="mdi mdi-trending-up"></i> </span>
                                                <h2 className="font-weight-normal mb-1"> 0 </h2>
                                                <p className="text-muted mb-3">Commandes</p>
                                            </div>
                                            <div className="progress progress-bar-alt-success progress-sm">
                                                <div className="progress-bar bg-danger" role="progressbar"
                                                    aria-valuenow="77" aria-valuemin="0" aria-valuemax="100"
                                                    style={{ width: "77%" }}>
                                                    <span className="sr-only">77% Complete</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6">
                                    <div className="card-box">
                                        <div className="dropdown float-right">
                                            <a href="#" className="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false">
                                                <i className="mdi mdi-dots-vertical"></i>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <a href="javascript:void(0);" className="dropdown-item">Liste des notifications</a>
                                                <a href="javascript:void(0);" className="dropdown-item">Envoyer une notification</a>
                                                <a href="javascript:void(0);" className="dropdown-item">Raffraichir</a>
                                            </div>
                                        </div>

                                        <h4 className="header-title mt-0 mb-3">Utilisateurs</h4>

                                        <div className="widget-box-2">
                                            <div className="widget-detail-2 text-right">
                                                <span className="badge badge-pink badge-pill float-left mt-3">32% <i className="mdi mdi-trending-up"></i> </span>
                                                <h2 className="font-weight-normal mb-1"> 0 </h2>
                                                <p className="text-muted mb-3">Utilisateurs</p>
                                            </div>
                                            <div className="progress progress-bar-alt-pink progress-sm">
                                                <div className="progress-bar bg-pink" role="progressbar"
                                                    aria-valuenow="77" aria-valuemin="0" aria-valuemax="100"
                                                    style={{ width: "77%" }}>
                                                    <span className="sr-only">77% Complete</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-4">
                                    <div className="card-box">
                                        <div className="dropdown float-right">
                                            <a href="#" className="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false">
                                                <i className="mdi mdi-dots-vertical"></i>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <a href="javascript:void(0);" className="dropdown-item">Voir les details</a>
                                                <a href="javascript:void(0);" className="dropdown-item">Raffraichir</a>
                                            </div>
                                        </div>

                                        <h4 className="header-title mt-0">Commentaires</h4>

                                        <div className="widget-chart text-center">
                                            <div id="morris-donut-example" dir="ltr" style={{ height: "245px" }} className="morris-chart"></div>
                                            <ul className="list-inline chart-detail-list mb-0">
                                                <li className="list-inline-item">
                                                    <h5 style={{ color: "#ff8acc" }}><i className="fa fa-circle mr-1"></i>Series A</h5>
                                                </li>
                                                <li className="list-inline-item">
                                                    <h5 style={{ color: "#5b69bc" }}><i className="fa fa-circle mr-1"></i>Series B</h5>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4">
                                    <div className="card-box">
                                        <div className="dropdown float-right">
                                            <a href="#" className="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false">
                                                <i className="mdi mdi-dots-vertical"></i>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-right">

                                                <a href="javascript:void(0);" className="dropdown-item">Voir les details</a>
                                                <a href="javascript:void(0);" className="dropdown-item">Raffraichir</a>

                                            </div>
                                        </div>
                                        <h4 className="header-title mt-0">Statistiques</h4>
                                        <div id="morris-bar-example" dir="ltr" style={{ height: "280px" }} className="morris-chart"></div>
                                    </div>
                                </div>
                                <div className="col-xl-4">
                                    <div className="card-box">
                                        <div className="dropdown float-right">
                                            <a href="#" className="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false">
                                                <i className="mdi mdi-dots-vertical"></i>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-right">

                                                <a href="javascript:void(0);" className="dropdown-item">Voir les details</a>
                                                <a href="javascript:void(0);" className="dropdown-item">Raffraichir</a>
                                            </div>
                                        </div>
                                        <h4 className="header-title mt-0">Mes Revenues</h4>
                                        <div id="morris-line-example" dir="ltr" style={{ height: "280px" }} className="morris-chart"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                        
                                <div className="col-xl-3 col-md-6">
                                    <div className="card-box widget-user">
                                        <div>
                                            <div className="avatar-lg float-left mr-3">
                                                <img src="assets/images/users/user-2.jpg" className="img-fluid rounded-circle" alt="user" />
                                            </div>
                                            <div className="wid-u-info">
                                                <h5 className="mt-0"> Billal Nitiema</h5>
                                                <p className="text-muted mb-1 font-13 text-truncate">bilal@yatouu.com</p>
                                                <small className="text-pink"><b>Directeur des Operations</b></small>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-md-6">
                                    <div className="card-box widget-user">
                                        <div>
                                            <div className="avatar-lg float-left mr-3">
                                                <img src="assets/images/users/user-1.jpg" className="img-fluid rounded-circle" alt="user" />
                                            </div>
                                            <div className="wid-u-info">
                                                <h5 className="mt-0">Desire Boutchoue</h5>
                                                <p className="text-muted mb-1 font-13 text-truncate">desire@gmail.com</p>
                                                <small className="text-success"><b>Directeur Technique</b></small>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-md-6">
                                    <div className="card-box widget-user">
                                        <div>
                                            <div className="avatar-lg float-left mr-3">
                                                <img src="assets/images/users/user-10.jpg" className="img-fluid rounded-circle" alt="user" />
                                            </div>
                                            <div className="wid-u-info">
                                                <h5 className="mt-0">Emmanuel Yapo</h5>
                                                <p className="text-muted mb-1 font-13 text-truncate">emmanuel@yatouu.com</p>
                                                <small className="text-info"><b>Directeur Analyste en Intelligence d'Affaires </b></small>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-xl-4">
                                    <div className="card-box">
                                        <div className="dropdown float-right">
                                            <a href="#" className="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false">
                                                <i className="mdi mdi-dots-vertical"></i>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <a href="javascript:void(0);" className="dropdown-item">Voir la liste</a>
                                                <a href="javascript:void(0);" className="dropdown-item">Envoyer un message</a>
                                                <a href="javascript:void(0);" className="dropdown-item">Raffraichir</a>
                                            </div>
                                        </div>

                                        <h4 className="header-title mb-3">Boite de reception</h4>

                                        <div className="inbox-widget">

                                            {/* <div class="inbox-item">
                                                <a href="#">
                                                    <div class="inbox-item-img"><img src="assets/images/users/user-1.jpg" class="rounded-circle" alt="" /></div>
                                                    <h5 class="inbox-item-author mt-0 mb-1">Chadengle</h5>
                                                    <p class="inbox-item-text">Hey! there I'm available...</p>
                                                    <p class="inbox-item-date">13:40 PM</p>
                                                </a>
                                            </div>

                                            <div class="inbox-item">
                                                <a href="#">
                                                    <div class="inbox-item-img"><img src="assets/images/users/user-2.jpg" class="rounded-circle" alt="" /></div>
                                                    <h5 class="inbox-item-author mt-0 mb-1">Tomaslau</h5>
                                                    <p class="inbox-item-text">I've finished it! See you so...</p>
                                                    <p class="inbox-item-date">13:34 PM</p>
                                                </a>
                                            </div>

                                            <div class="inbox-item">
                                                <a href="#">
                                                    <div class="inbox-item-img"><img src="assets/images/users/user-3.jpg" class="rounded-circle" alt="" /></div>
                                                    <h5 class="inbox-item-author mt-0 mb-1">Stillnotdavid</h5>
                                                    <p class="inbox-item-text">This theme is awesome!</p>
                                                    <p class="inbox-item-date">13:17 PM</p>
                                                </a>
                                            </div>

                                            <div class="inbox-item">
                                                <a href="#">
                                                    <div class="inbox-item-img"><img src="assets/images/users/user-4.jpg" class="rounded-circle" alt="" /></div>
                                                    <h5 class="inbox-item-author mt-0 mb-1">Kurafire</h5>
                                                    <p class="inbox-item-text">Nice to meet you</p>
                                                    <p class="inbox-item-date">12:20 PM</p>
                                                </a>
                                            </div>

                                            <div class="inbox-item">
                                                <a href="#">
                                                    <div class="inbox-item-img"><img src="assets/images/users/user-5.jpg" class="rounded-circle" alt="" /></div>
                                                    <h5 class="inbox-item-author mt-0 mb-1">Shahedk</h5>
                                                    <p class="inbox-item-text">Hey! there I'm available...</p>
                                                    <p class="inbox-item-date">10:15 AM</p>
                                                </a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-8">
                                    <div className="card-box">
                                        <div className="dropdown float-right">
                                            <a href="#" className="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false">
                                                <i className="mdi mdi-dots-vertical"></i>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-right">

                                                <a href="javascript:void(0);" className="dropdown-item">Action</a>

                                                <a href="javascript:void(0);" className="dropdown-item">Another action</a>

                                                <a href="javascript:void(0);" className="dropdown-item">Something else</a>

                                                <a href="javascript:void(0);" className="dropdown-item">Separated link</a>
                                            </div>
                                        </div>

                                        <h4 className="header-title mt-0 mb-3">Dernieres commandes</h4>

                                        <div className="table-responsive">
                                            <table className="table table-hover mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Logo</th>
                                                        <th>Nom</th>
                                                        <th>Proprietaire</th>
                                                        <th>Status</th>
                                                        <th>Assign</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {/* <tr>
                                                        <td>1</td>
                                                        <td>Adminto Admin v1</td>
                                                        <td>01/01/2017</td>
                                                        <td>26/04/2017</td>
                                                        <td><span class="badge badge-danger">Released</span></td>
                                                        <td>Coderthemes</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>Adminto Frontend v1</td>
                                                        <td>01/01/2017</td>
                                                        <td>26/04/2017</td>
                                                        <td><span class="badge badge-success">Released</span></td>
                                                        <td>Adminto admin</td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>Adminto Admin v1.1</td>
                                                        <td>01/05/2017</td>
                                                        <td>10/05/2017</td>
                                                        <td><span class="badge badge-pink">Pending</span></td>
                                                        <td>Coderthemes</td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>Adminto Frontend v1.1</td>
                                                        <td>01/01/2017</td>
                                                        <td>31/05/2017</td>
                                                        <td><span class="badge badge-purple">Work in Progress</span>
                                                        </td>
                                                        <td>Adminto admin</td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>Adminto Admin v1.3</td>
                                                        <td>01/01/2017</td>
                                                        <td>31/05/2017</td>
                                                        <td><span class="badge badge-warning">Coming soon</span></td>
                                                        <td>Coderthemes</td>
                                                    </tr>

                                                    <tr>
                                                        <td>6</td>
                                                        <td>Adminto Admin v1.3</td>
                                                        <td>01/01/2017</td>
                                                        <td>31/05/2017</td>
                                                        <td><span class="badge badge-primary">Coming soon</span></td>
                                                        <td>Adminto admin</td>
                                                    </tr> */}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>

            </div>
            <div className="rightbar-overlay"></div>
        </>
    )
};
export default Panel;