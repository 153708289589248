import React, { useState } from "react";

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../../actions/auth/auth';
import Logo from "../../assets/images/icon.png";

const Navbar = () => {

    const Dispacth = useDispatch();

    const [marque, setMarque] = useState(false)
    const [produits, setProduits] = useState(false)
    const [baniere, setBaniere] = useState(false)
    const [pub, setPub] = useState(false)
    const [paiements, setPaiements] = useState(false)

    const handleMarque = () => {
        setMarque(!marque)
    }

    const handlePaiements = () => {
        setPaiements(!paiements)
    }

    const handleProduits = () => {
       setProduits(!produits)
    }

    const handleBaniere = () => {
        setBaniere(!baniere)
    }

    const handlePub = () => {
      setPub(!pub)
    }


    return (
        <div className="left-side-menu" style={{ overflow: "scroll" }}>
            <div className="slimscroll-menu">
                <div className="user-box text-center">
                    <img src={Logo} alt="user-img" title="Mat Helme" className="rounded-circle img-thumbnail avatar-lg" />
                    <div className="dropdown">
                        <a href="#" className="text-dark dropdown-toggle h5 mt-2 mb-1 d-block" data-toggle="dropdown">Admin</a>
                        <div className="dropdown-menu user-pro-dropdown">
                            <a href="javascript:void(0);" className="dropdown-item notify-item">
                                <i className="fe-user mr-1"></i>
                                <span>Mon compte</span>
                            </a>
                            <a href="javascript:void(0);" className="dropdown-item notify-item">
                                <i className="fe-settings mr-1"></i>
                                <span>Parametres</span>
                            </a>
                            <a href="javascript:void(0);" className="dropdown-item notify-item">
                                <i className="fe-lock mr-1"></i>
                                <span>Verouiller mon ecran</span>
                            </a>
                            <a onClick={() => Dispacth(logOut())} style={{cursor:"pointer"}} className="dropdown-item notify-item">
                                <i className="fe-log-out mr-1"></i>
                                <span>Deconnexion</span>
                            </a>
                        </div>
                    </div>
                    <ul className="list-inline">
                        <li className="list-inline-item">
                            <a href="#" className="text-muted">
                                <i className="mdi mdi-settings"></i>
                            </a>
                        </li>
                        <li className="list-inline-item">
                            <a onClick={() => Dispacth(logOut())} className="text-custom">
                                <i className="mdi mdi-power"></i>
                            </a>
                        </li>
                    </ul>
                </div>

                <div id="sidebar-menu" >
                    <ul className="metismenu" id="side-menu">
                        <li className="menu-title">Navigation</li>
                        <li>
                            <Link to="/">
                                <i className="mdi mdi-view-dashboard"></i>
                                <span> Panel </span>
                            </Link>
                        </li>
                        <li >
                            <a onClick={handleMarque} href="javascript: void(0);">
                                <i className="mdi mdi-invert-colors"></i>
                                <span> Marques </span>
                                <span className="menu-arrow"></span>
                            </a>
                            <ul className="nav-second-level" aria-expanded="false">
                                {
                                    marque ?
                                        <>
                                            <li><Link to="/create-marque">Creer une marque</Link></li>
                                            <li><Link to="/brand-list">Liste des marques</Link></li>
                                        </>
                                        :
                                        null
                                }
                            </ul>
                        </li>
                        <li>
                            <a onClick={handleBaniere} href="javascript: void(0);">
                                <i className="mdi mdi-texture"></i>
                                <span> Banieres </span>
                                <span className="menu-arrow"></span>
                            </a>
                            <ul className="nav-second-level" aria-expanded="false">
                                {
                                    baniere ?
                                        <>
                                            <li><Link to='/add-baniere'>Ajouter une baniere</Link></li>
                                            <li><Link to='/baniere-list' >Liste des banieres</Link></li>
                                        </>
                                        :
                                        null
                                }
                            </ul>
                        </li>

                        <li>
                            <a onClick={handlePub} href="javascript: void(0);">
                                <i className="mdi mdi-texture"></i>
                                <span> Pubs </span>
                                <span className="menu-arrow"></span>
                            </a>
                            <ul className="nav-second-level" aria-expanded="false">
                                {
                                    pub ?
                                        <>
                                            <li><Link to='/add-pub'>Ajouter une pub</Link></li>
                                            <li><Link to='/pub-list' >Liste des pub</Link></li>
                                        </>
                                        :
                                        null
                                }
                            </ul>
                        </li>
                        <li>
                            <a onClick={handleProduits} href="javascript: void(0);">
                                <i className="mdi mdi-texture"></i>
                                <span> Produits </span>
                                <span className="menu-arrow"></span>
                            </a>
                            <ul className="nav-second-level" aria-expanded="false">
                                {
                                    produits ?
                                        <>
                                            <li><Link to='/create-product'>Ajouter un produit</Link></li>
                                            <li><Link to='/product-list' >Liste des produits</Link></li>
                                        </>
                                        :
                                        null
                                }
                            </ul>
                        </li>
                        <li>
                            <a onClick={handlePaiements} href="javascript: void(0);">
                                <i className="mdi mdi-texture"></i>
                                <span> Paiements </span>
                                <span className="menu-arrow"></span>
                            </a>
                            <ul className="nav-second-level" aria-expanded="false">
                                {
                                    paiements ?
                                        <>
                                            <li><a href="form-elements.html">Statut des paiments</a></li>
                                            <li><a href="form-advanced.html">Creer un paiement</a></li>
                                        </>
                                        :
                                        null
                                }
                            </ul>
                        </li>

                        {/* <li>
                            <a href="javascript: void(0);">
                                <i class="mdi mdi-view-list"></i>
                                <span> Tables </span>
                                <span class="menu-arrow"></span>
                            </a>
                            <ul class="nav-second-level" aria-expanded="false">
                               <li><a href="tables-basic.html">Basic Tables</a></li>
                                <li><a href="tables-datatable.html">Data Tables</a></li>
                                <li><a href="tables-responsive.html">Responsive Table</a></li>
                                <li><a href="tables-editable.html">Editable Table</a></li>
                                <li><a href="tables-tablesaw.html">Tablesaw Table</a></li>
                            </ul>
                        </li> */}

                        {/* <li>
                            <a href="javascript: void(0);">
                                <i class="mdi mdi-chart-donut-variant"></i>
                                <span> Charts </span>
                                <span class="menu-arrow"></span>
                            </a>
                            <ul class="nav-second-level" aria-expanded="false">
                                 <li><a href="charts-flot.html">Flot Charts</a></li>
                                <li><a href="charts-morris.html">Morris Charts</a></li>
                                <li><a href="charts-chartist.html">Chartist Charts</a></li>
                                <li><a href="charts-chartjs.html">Chartjs Charts</a></li>
                                <li><a href="charts-other.html">Other Charts</a></li> 
                            </ul>
                        </li> */}

                        {/* <li>
                            <a href="calendar.html">
                                <i class="mdi mdi-calendar"></i>
                                <span> Calendar </span>
                            </a>
                        </li>

                        <li>
                            <a href="inbox.html">
                                <i class="mdi mdi-email"></i>
                                <span> Mail </span>
                            </a>
                        </li> */}

                        {/* <li>
                            <a href="javascript: void(0);">
                                <i class="mdi mdi-page-layout-sidebar-left"></i>
                                <span class="badge badge-purple float-right">New</span>
                                <span> Layouts </span>
                            </a>
                            <ul class="nav-second-level" aria-expanded="false">
                                <li><a href="layouts-sidebar-sm.html">Small Sidebar</a></li>
                                <li><a href="layouts-dark-sidebar.html">Dark Sidebar</a></li>
                                <li><a href="layouts-dark-topbar.html">Dark Topbar</a></li>
                                <li><a href="layouts-preloader.html">Preloader</a></li>
                                <li><a href="layouts-sidebar-collapsed.html">Sidebar Collapsed</a></li>
                                <li><a href="layouts-boxed.html">Boxed</a></li> 
                            </ul>
                        </li> */}

                        {/* <li>
                            <a href="javascript: void(0);">
                                <i class="mdi mdi-google-pages"></i>
                                <span> Pages </span>
                                <span class="menu-arrow"></span>
                            </a>
                            <ul class="nav-second-level" aria-expanded="false">
                                 <li><a href="pages-starter.html">Starter Page</a></li>
                                <li><a href="pages-login.html">Login</a></li>
                                <li><a href="pages-register.html">Register</a></li>
                                <li><a href="pages-recoverpw.html">Recover Password</a></li>
                                <li><a href="pages-lock-screen.html">Lock Screen</a></li>
                                <li><a href="pages-confirm-mail.html">Confirm Mail</a></li>
                                <li><a href="pages-404.html">Error 404</a></li>
                                <li><a href="pages-500.html">Error 500</a></li> 
                            </ul>
                        </li> */}

                        {/* <li>
                            <a href="javascript: void(0);">
                                <i class="mdi mdi-layers"></i>
                                <span> Extra Pages </span>
                                <span class="menu-arrow"></span>
                            </a>
                            <ul class="nav-second-level" aria-expanded="false">
                                 <li><a href="extras-projects.html">Projects</a></li>
                                <li><a href="extras-tour.html">Tour</a></li>
                                <li><a href="extras-taskboard.html">Taskboard</a></li>
                                <li><a href="extras-taskdetail.html">Task Detail</a></li>
                                <li><a href="extras-profile.html">Profile</a></li>
                                <li><a href="extras-maps.html">Maps</a></li>
                                <li><a href="extras-contact.html">Contact list</a></li>
                                <li><a href="extras-pricing.html">Pricing</a></li>
                                <li><a href="extras-timeline.html">Timeline</a></li>
                                <li><a href="extras-invoice.html">Invoice</a></li>
                                <li><a href="extras-faq.html">FAQs</a></li>
                                <li><a href="extras-gallery.html">Gallery</a></li>
                                <li><a href="extras-email-templates.html">Email Templates</a></li>
                                <li><a href="extras-maintenance.html">Maintenance</a></li>
                                <li><a href="extras-comingsoon.html">Coming Soon</a></li> 
                            </ul>
                        </li> */}

                        {/* <li>
                            <a href="javascript: void(0);">
                                <i class="mdi mdi-share-variant"></i>
                                <span> Multi Level </span>
                                <span class="menu-arrow"></span>
                            </a>
                            <ul class="nav-second-level nav" aria-expanded="false">
                                <li>
                                    <a href="javascript: void(0);">Level 1.1</a>
                                </li>
                                <li>
                                    <a href="javascript: void(0);" aria-expanded="false">Level 1.2
                                        <span class="menu-arrow"></span>
                                    </a>
                                    <ul class="nav-third-level nav" aria-expanded="false">
                                        <li>
                                            <a href="javascript: void(0);">Level 2.1</a>
                                        </li>
                                        <li>
                                            <a href="javascript: void(0);">Level 2.2</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li> */}
                    </ul>
                </div>
                <div className="clearfix"></div>
            </div>
        </div>
    )
};
export default Navbar;