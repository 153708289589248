
import S3FileUpload from "react-s3";
import {S3_BUCKET,REGION,ACCESS_KEY,SECRET_ACCES_KEY,DIRECTORY_NAME} from "../../assets/utils/config";

//IMPORT URL
import {CREATE_BRAND_URL, GET_BRAND_LIST_URL, ADD_PRODUCT_URL, GET_PRODUCT_LIST_URL, ADD_BANIERE_URL,
     GET_BANIERE_LIST_URL, CHANGE_BANIERE_STATUS_URL, CHANGE_PUB_STATUS_URL, ADD_PUB_URL, GET_PUB_LIST_URL,
      CHANGE_POPULAIRE_STATUS_URL, CHANGE_SHOW_STATUS_URL, CHANGE_PRODUCT_POPULAIRE_STATUS_URL, CHANGE_PRODUCT_PLUSREGARDES_STATUS_URL, 
      CHANGE_PRODUCT_NOUVEAUTES_STATUS_URL, CHANGE_PRODUCT_SHOW_STATUS_URL, UPLOAD_FILE_URL} from "../../urls/home/home";

// IMPORT TYPES
import {UPLOAD_FILE_START, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_FAIL, CREATE_BRAND_START, CREATE_BRAND_SUCCESS, CREATE_BRAND_FAIL,
     GET_BRAND_LIST_START, GET_BRAND_LIST_SUCCESS, GET_BRAND_LIST_FAIL, ADD_PRODUCT_START, ADD_PRODUCT_SUCCESS, ADD_PRODUCT_FAIL,
    GET_PRODUCT_LIST_START, GET_PRODUCT_LIST_SUCCESS, GET_PRODUCT_LIST_FAIL, ADD_BANIERE_START, ADD_BANIERE_SUCCESS, ADD_BANIERE_FAIL,
    GET_BANIERE_LIST_START, GET_BANIERE_LIST_SUCCESS, GET_BANIERE_LIST_FAIL, CHANGE_BANIERE_STATUS_START, CHANGE_BANIERE_STATUS_SUCCESS, CHANGE_BANIERE_STATUS_FAIL,
    ADD_PUB_START, ADD_PUB_SUCCESS, ADD_PUB_FAIL, GET_PUB_LIST_START, GET_PUB_LIST_SUCCESS, GET_PUB_LIST_FAIL, CHANGE_PUB_STATUS_START, CHANGE_PUB_STATUS_SUCCESS, CHANGE_PUB_STATUS_FAIL,
    CHANGE_POPULAIRE_STATUS_START, CHANGE_POPULAIRE_STATUS_SUCCESS, CHANGE_POPULAIRE_STATUS_FAIL, CHANGE_SHOW_STATUS_START, CHANGE_SHOW_STATUS_SUCCESS, CHANGE_SHOW_STATUS_FAIL,
    CHANGE_PRODUCT_POPULAIRE_STATUS_START, CHANGE_PRODUCT_POPULAIRE_STATUS_SUCCESS, CHANGE_PRODUCT_POPULAIRE_STATUS_FAIL, CHANGE_PRODUCT_PLUS_REGARDES_STATUS_START, CHANGE_PRODUCT_PLUS_REGARDES_STATUS_SUCCESS,
    CHANGE_PRODUCT_NOUVEAUTES_STATUS_FAIL, CHANGE_PRODUCT_NOUVEAUTES_STATUS_START, CHANGE_PRODUCT_NOUVEAUTES_STATUS_SUCCESS, CHANGE_PRODUCT_PLUS_REGARDES_STATUS_FAIL,
    CHANGE_PRODUCT_SHOW_STATUS_START, CHANGE_PRODUCT_SHOW_STATUS_SUCCESS, CHANGE_PRODUCT_SHOW_STATUS_FAIL
    } from "../../types/home/home";

const config = {
    bucketName:S3_BUCKET,
    region:REGION,
    accessKeyId:ACCESS_KEY,
    secretAccessKey:SECRET_ACCES_KEY,
    dirName:DIRECTORY_NAME
}

export const uploadFileStart = () => {
    return{
        type: UPLOAD_FILE_START
    }
}

export const uploadFileSuccess = (filename) => {
    return{
        type: UPLOAD_FILE_SUCCESS,
        filename:filename
    }
}

export const uploadFileFail = () => {
    return{
        type: UPLOAD_FILE_FAIL
    }
}

export const uploadFileService = (data) => {
    console.log(data)
    const formData = new FormData();
    formData.append('file', data);
    return dispatch => {
        dispatch(uploadFileStart())
        fetch(UPLOAD_FILE_URL, {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(response => {
                console.log("==>>>", response)
                if (response.status === 200) {
                    dispatch(uploadFileSuccess(response.filedestination))
                }
                else {
                    dispatch(uploadFileFail())
                }
            })
            .catch((err) => {
                console.log("errrrrr ====>>>>", err)
            })
    }
}

export const createBrandStart = () => {
    return{
        type: CREATE_BRAND_START
    }
}

export const createBrandSuccess = () => {
    return{
        type: CREATE_BRAND_SUCCESS
    }
}

export const createBrandFail = (error) => {
    return{
        type: CREATE_BRAND_FAIL,
        error:error
    }
}

export const createBrandService = (name, proprietaire, telephone, description, type, logo, token) => {
    return dispatch => {
        dispatch(createBrandStart())
        fetch(CREATE_BRAND_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':"Bearer "+ token
            },
            body: JSON.stringify({
                "name": name,
                "proprietaire": proprietaire,
                "description": description,
                "type": type,
                "telephone": telephone,
                "logo":logo
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(createBrandSuccess());
                }
                else {
                    dispatch(createBrandFail("error"))
                }
            })
            .catch(error => {
                dispatch(createBrandFail(error.message))
            })
    }
}

export const getBrandListStart = () => {
    return{
        type: GET_BRAND_LIST_START
    }
}

export const getBrandListSuccess = (brandlist) => {
    return{
        type: GET_BRAND_LIST_SUCCESS,
        brandlist:brandlist
    }
}

export const getBrandListFail = (error) => {
    return{
        type: GET_BRAND_LIST_FAIL,
        error:error
    }
}

export const getBrandListService = (token) => {
    return dispatch => {
        dispatch(getBrandListStart())
        fetch(GET_BRAND_LIST_URL, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':"Bearer "+ token
            }
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getBrandListSuccess(response.data.response));
                }
                else {
                    dispatch(getBrandListFail("error"))
                }
            })
            .catch(error => {
                dispatch(getBrandListFail(error.message))
            })
    }
}

export const addProductStart = () => {
    return{
        type: ADD_PRODUCT_START
    }
}

export const addProductSuccess = () => {
    return{
        type: ADD_PRODUCT_SUCCESS
    }
}

export const addProductFail = (error) => {
    return{
        type: ADD_PRODUCT_FAIL,
        error:error
    }
}

export const addProductService = (name, price, category, description, prixvente, image,  brandId, token) => {
    return dispatch => {
        dispatch(addProductStart())
        fetch(ADD_PRODUCT_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':"Bearer "+ token
            },
            body: JSON.stringify({
                "name": name,
                "price": price,
                "category": category,
                "description": description,
                "prixvente": prixvente,
                "image":image,
                "brandId":brandId
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(addProductSuccess());
                }
                else {
                    dispatch(addProductFail("error"))
                }
            })
            .catch(error => {
                dispatch(addProductFail(error.message))
            })
    }
}

export const getProductListStart = () => {
    return{
        type: GET_PRODUCT_LIST_START
    }
}

export const getProductListSuccess = (productlist) => {
    return{
        type: GET_PRODUCT_LIST_SUCCESS,
        productlist:productlist
    }
}

export const getProductListFail = (error) => {
    return{
        type: GET_PRODUCT_LIST_FAIL,
        error:error
    }
}

export const getProductListService = (token) => {
    return dispatch => {
        dispatch(getProductListStart())
        fetch(GET_PRODUCT_LIST_URL, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':"Bearer "+ token
            }
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getProductListSuccess(response.data.response));
                }
                else {
                    dispatch(getProductListFail("error"))
                }
            })
            .catch(error => {
                dispatch(getProductListFail(error.message))
            })
    }
}

export const addBaniereStart = () => {
    return{
        type: ADD_BANIERE_START
    }
}

export const addBaniereSuccess = () => {
    return{
        type: ADD_BANIERE_SUCCESS
    }
}

export const addBaniereFail = (error) => {
    return{
        type: ADD_BANIERE_FAIL,
        error:error
    }
}

export const addBaniereService = (url,token) => {
    return dispatch => {
        dispatch(addBaniereStart())
        fetch(ADD_BANIERE_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':"Bearer "+ token
            },
            body: JSON.stringify({
                "url": url
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(addBaniereSuccess());
                }
                else {
                    dispatch(addBaniereFail("error"))
                }
            })
            .catch(error => {
                dispatch(addBaniereFail(error.message))
            })
    }
}

export const getBaniereListStart = () => {
    return{
        type: GET_BANIERE_LIST_START
    }
}

export const getBaniereListSuccess = (banierelist) => {
    return{
        type: GET_BANIERE_LIST_SUCCESS,
        banierelist:banierelist
    }
}

export const getBaniereListFail = (error) => {
    return{
        type: GET_BANIERE_LIST_FAIL,
        error:error
    }
}

export const getBaniereListService = (token) => {
    return dispatch => {
        dispatch(getBaniereListStart())
        fetch(GET_BANIERE_LIST_URL, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':"Bearer "+ token
            }
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getBaniereListSuccess(response.data.response));
                }
                else {
                    dispatch(getBaniereListFail("error"))
                }
            })
            .catch(error => {
                dispatch(getBaniereListFail(error.message))
            })
    }
}

export const changeBaniereStatusStart = () => {
    return{
        type: CHANGE_BANIERE_STATUS_START
    }
}

export const changeBaniereStatusSuccess = () => {
    return{
        type: CHANGE_BANIERE_STATUS_SUCCESS
    }
}

export const changeBaniereStatusFail = (error) => {
    return{
        type: CHANGE_BANIERE_STATUS_FAIL,
        error:error
    }
}

export const changeBaniereStatusService = (banniereId,status,token) => {
    return dispatch => {
        dispatch(changeBaniereStatusStart())
        fetch(CHANGE_BANIERE_STATUS_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':"Bearer "+ token
            },
            body: JSON.stringify({
                "banniereId": banniereId,
                "status":status
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getBaniereListService(token));
                }
                else {
                    dispatch(changeBaniereStatusFail("error"))
                }
            })
            .catch(error => {
                dispatch(changeBaniereStatusFail(error.message))
            })
    }
}

export const addPubStart = () => {
    return{
        type: ADD_PUB_START
    }
}

export const addPubSuccess = () => {
    return{
        type: ADD_PUB_SUCCESS
    }
}

export const addPubFail = (error) => {
    return{
        type: ADD_PUB_FAIL,
        error:error
    }
}

export const addPubService = (url,token) => {
    return dispatch => {
        dispatch(addPubStart())
        fetch(ADD_PUB_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':"Bearer "+ token
            },
            body: JSON.stringify({
                "url": url
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(addPubSuccess());
                }
                else {
                    dispatch(addPubFail("error"))
                }
            })
            .catch(error => {
                dispatch(addPubFail(error.message))
            })
    }
}

export const getPubListStart = () => {
    return{
        type: GET_PUB_LIST_START
    }
}

export const getPubListSuccess = (publist) => {
    return{
        type: GET_PUB_LIST_SUCCESS,
        publist:publist
    }
}

export const getPubListFail = (error) => {
    return{
        type: GET_PUB_LIST_FAIL,
        error:error
    }
}

export const getPubListService = (token) => {
    return dispatch => {
        dispatch(getPubListStart())
        fetch(GET_PUB_LIST_URL, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':"Bearer "+ token
            }
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getPubListSuccess(response.data.response));
                }
                else {
                    dispatch(getPubListFail("error"))
                }
            })
            .catch(error => {
                dispatch(getPubListFail(error.message))
            })
    }
}

export const changePubStatusStart = () => {
    return{
        type: CHANGE_PUB_STATUS_START
    }
}

export const changePubStatusSuccess = () => {
    return{
        type: CHANGE_PUB_STATUS_SUCCESS
    }
}

export const changePubStatusFail = (error) => {
    return{
        type: CHANGE_PUB_STATUS_FAIL,
        error:error
    }
}

export const changePubStatusService = (pubId,status,token) => {
    return dispatch => {
        dispatch(changePubStatusStart())
        fetch(CHANGE_PUB_STATUS_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':"Bearer "+ token
            },
            body: JSON.stringify({
                "pubId": pubId,
                "status":status
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getPubListService(token));
                }
                else {
                    dispatch(changePubStatusFail("error"))
                }
            })
            .catch(error => {
                dispatch(changePubStatusFail(error.message))
            })
    }
}

export const changeShowStatusStart = () => {
    return{
        type: CHANGE_SHOW_STATUS_START
    }
}

export const changeShowStatusSuccess = () => {
    return{
        type: CHANGE_SHOW_STATUS_SUCCESS
    }
}

export const changeShowStatusFail = (error) => {
    return{
        type: CHANGE_SHOW_STATUS_FAIL,
        error:error
    }
}

export const changeShowStatusService = (brandId,status,token) => {
    return dispatch => {
        dispatch(changeShowStatusStart())
        fetch(CHANGE_SHOW_STATUS_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':"Bearer "+ token
            },
            body: JSON.stringify({
                "brandId": brandId,
                "status":status
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getBrandListService(token));
                }
                else {
                    dispatch(changeShowStatusFail("error"))
                }
            })
            .catch(error => {
                dispatch(changeShowStatusFail(error.message))
            })
    }
}

export const changePopulaireStatusStart = () => {
    return{
        type: CHANGE_POPULAIRE_STATUS_START
    }
}

export const changePopulaireStatusSuccess = () => {
    return{
        type: CHANGE_POPULAIRE_STATUS_SUCCESS
    }
}

export const changePopulaireStatusFail = (error) => {
    return{
        type: CHANGE_POPULAIRE_STATUS_FAIL,
        error:error
    }
}

export const changePopulaireStatusService = (brandId,status,token) => {
    return dispatch => {
        dispatch(changePopulaireStatusStart())
        fetch(CHANGE_POPULAIRE_STATUS_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':"Bearer "+ token
            },
            body: JSON.stringify({
                "brandId": brandId,
                "status":status
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getBrandListService(token));
                }
                else {
                    dispatch(changePopulaireStatusFail("error"))
                }
            })
            .catch(error => {
                dispatch(changePopulaireStatusFail(error.message))
            })
    }
}

export const changeProductPopulaireStatusStart = () => {
    return{
        type: CHANGE_PRODUCT_POPULAIRE_STATUS_START
    }
}

export const changeProductPopulaireStatusSuccess = () => {
    return{
        type: CHANGE_PRODUCT_POPULAIRE_STATUS_SUCCESS
    }
}

export const changeProductPopulaireStatusFail = (error) => {
    return{
        type: CHANGE_PRODUCT_POPULAIRE_STATUS_FAIL,
        error:error
    }
}

export const changeProductPopulaireStatusService = (productId,status,token) => {
    return dispatch => {
        dispatch(changeProductPopulaireStatusStart())
        fetch(CHANGE_PRODUCT_POPULAIRE_STATUS_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':"Bearer "+ token
            },
            body: JSON.stringify({
                "productId": productId,
                "status":status
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getProductListService(token));
                }
                else {
                    dispatch(changeProductPopulaireStatusFail("error"))
                }
            })
            .catch(error => {
                dispatch(changeProductPopulaireStatusFail(error.message))
            })
    }
}

export const changeProductPlusRegardesStatusStart = () => {
    return{
        type: CHANGE_PRODUCT_PLUS_REGARDES_STATUS_START
    }
}

export const changeProductPlusRegardesStatusSuccess = () => {
    return{
        type: CHANGE_PRODUCT_PLUS_REGARDES_STATUS_SUCCESS
    }
}

export const changeProductPlusRegardesStatusFail = (error) => {
    return{
        type: CHANGE_PRODUCT_PLUS_REGARDES_STATUS_FAIL,
        error:error
    }
}

export const changeProductPlusRegardesStatusService = (productId,status,token) => {
    return dispatch => {
        dispatch(changeProductPlusRegardesStatusStart())
        fetch(CHANGE_PRODUCT_PLUSREGARDES_STATUS_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':"Bearer "+ token
            },
            body: JSON.stringify({
                "productId": productId,
                "status":status
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getProductListService(token));
                }
                else {
                    dispatch(changeProductPlusRegardesStatusFail("error"))
                }
            })
            .catch(error => {
                dispatch(changeProductPlusRegardesStatusFail(error.message))
            })
    }
}

export const changeProductNouveauteStatusStart = () => {
    return{
        type: CHANGE_PRODUCT_NOUVEAUTES_STATUS_START
    }
}

export const changeProductNouveauteStatusSuccess = () => {
    return{
        type: CHANGE_PRODUCT_NOUVEAUTES_STATUS_SUCCESS
    }
}

export const changeProductNouveauteStatusFail = (error) => {
    return{
        type: CHANGE_PRODUCT_NOUVEAUTES_STATUS_FAIL,
        error:error
    }
}

export const changeProductNouveauteStatusService = (productId,status,token) => {
    return dispatch => {
        dispatch(changeProductNouveauteStatusStart())
        fetch(CHANGE_PRODUCT_NOUVEAUTES_STATUS_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':"Bearer "+ token
            },
            body: JSON.stringify({
                "productId": productId,
                "status":status
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getProductListService(token));
                }
                else {
                    dispatch(changeProductNouveauteStatusFail("error"))
                }
            })
            .catch(error => {
                dispatch(changeProductNouveauteStatusFail(error.message))
            })
    }
}

export const changeProductShowStatusStart = () => {
    return{
        type: CHANGE_PRODUCT_SHOW_STATUS_START
    }
}

export const changeProductShowStatusSuccess = () => {
    return{
        type: CHANGE_PRODUCT_SHOW_STATUS_SUCCESS
    }
}

export const changeProductShowStatusFail = (error) => {
    return{
        type: CHANGE_PRODUCT_SHOW_STATUS_FAIL,
        error:error
    }
}

export const changeProductShowStatusService = (productId,status,token) => {
    return dispatch => {
        dispatch(changeProductShowStatusStart())
        fetch(CHANGE_PRODUCT_SHOW_STATUS_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization':"Bearer "+ token
            },
            body: JSON.stringify({
                "productId": productId,
                "status":status
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(getProductListService(token));
                }
                else {
                    dispatch(changeProductShowStatusFail("error"))
                }
            })
            .catch(error => {
                dispatch(changeProductShowStatusFail(error.message))
            })
    }
}