import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import Switch from "react-switch";

import Navbar from "../../components/navbar/navbar";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Loader from "../../components/loader/loader";


import { getBaniereListService, changeBaniereStatusService } from "../../actions/home/home";

const BaniereList = () => {

    const token = useSelector((state) => state.AuthReducer.token);

    const Dispacth = useDispatch();

    useEffect(() => {
        Dispacth(getBaniereListService(token))
    }, []);


    const loading = useSelector((state) => state.HomeReducer.loading);
    const banierelist = useSelector((state) => state.HomeReducer.banierelist);

    const handleSwitch = (switchstatus, item) => {
        Dispacth(changeBaniereStatusService(item.id, !switchstatus, token))
    }


    let baniereList = banierelist.map((item, index) => {
        return (
            <tr key={index}>
                <td>{index + 1}
                </td>
                <td>
                    <img src={item.url ? item.url : ""} className="small-logo-list" style={{ width: 40, height: 40, borderRadius: 50 }} />
                </td>
                <td> <Switch onChange={() => handleSwitch(item.show, item)} checked={item.show} /> </td>

                <td>
                    <ul class="list-inline applicant-list-actions">

                        <li class="list-inline-item">
                            <a href="#" class="text-muted">
                                <i
                                    onClick={() => {
                                        //   openViewTransporteur(item);
                                    }}
                                    class="mdi mdi-eye btn-styled"></i>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a href="#" class="text-muted">
                                <i
                                    onClick={() => {
                                        // openEditTransporteur(item);
                                    }}

                                    class="mdi mdi-border-color btn-styled"></i>
                            </a>
                        </li>

                        <li class="list-inline-item">
                            <a class="text-custom">
                                <i
                                    onClick={(item) => {
                                        // openConfirmDelete(item);
                                    }}
                                    class="mdi mdi-delete btn-styled"></i>
                            </a>
                        </li>
                    </ul>
                </td>
            </tr>
        );
    });


    return (
        <>
            <div id="wrapper">
                <Header />
                <Navbar />
                <div class="content-page">
                    <div class="content">
                        <div class="input-group-append btn-go-back">
                            <Link to="/">
                                <button class="btn" type="submit">
                                    <i class="mdi mdi-arrow-left"></i>
                                </button>
                            </Link>
                        </div>

                        <div class="input-group-append btn-go-back">
                            <Link to="/">
                                <button class="btn" type="submit">
                                    <i class="mdi mdi-arrow-left"></i>
                                </button>
                            </Link>
                        </div>

                        <div class="container-fluid">
                            <div className="table-responsive">
                                <table className="table table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Image</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="set-relative">
                                        {loading ? (
                                            <div className="loader-parent height-full">
                                                <Loader />
                                            </div>
                                        ) : (
                                            baniereList
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default BaniereList;
