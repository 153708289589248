import React, { useState } from 'react';
// IMPORT DEPENDENCIES
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginAdminService } from '../../actions/auth/auth';

import Loader from "../../components/loader/loader";
import Logo from "../../assets/images/icon.png";

const Login = () => {

    const Dispacth = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const token = useSelector(state => state.AuthReducer.token);
    
    const role = useSelector(state => state.AuthReducer.role);
    const loading = useSelector(state => state.AuthReducer.loading);
    const errorserver = useSelector(state => state.AuthReducer.error);

    const handleEmail = (e) => {
        setError(false)
        setEmail(e.target.value)
    }

    const handlePassword = (e) => {
        setError(false)
        setPassword(e.target.value)
    }

    const submitHandler = (e) => {
        e.preventDefault();
        if (email !== null && email.trim() !== "" && password !== null && password.trim() !== "" && email !== '' && password !== '') {
            Dispacth(loginAdminService(email, password))
        }
        else {
            setError(true)
        }
    }

    let authRedirect = null;
    if (token && (role === 'admin')) {
        authRedirect = <Redirect to="/" />
    }

    return (
        <div className="authentication-bg">
              {authRedirect}
            <div className="account-pages mt-5 mb-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6 col-xl-5">
                            <div className="text-center">
                                    <span><img src={Logo} alt="Logo" height="150" /></span>
                                <p className="text-muted mt-2 mb-4"></p>
                            </div>
                            <div className="card">
                                <div className="card-body p-4">
                                    <div className="text-center mb-4">
                                        <h4 className="text-uppercase mt-0">Se connecter</h4>
                                    </div>
                                    <form onSubmit={submitHandler} >
                                        <div className="form-group mb-3">
                                            <label htmlFor="emailaddress">Email addresse</label>
                                            <input className="form-control" type="email" id="emailaddress" value={email} onChange={handleEmail} placeholder="Entrez votre email"/>
                                        </div>

                                        <div className="form-group mb-3">
                                            <label htmlFor="password">Mot de passe</label>
                                            <input className="form-control" type="password" value={password} onChange={handlePassword}  id="password" placeholder="Entrez votre mot de passe"/>
                                        </div>
                                        <div className="form-group mb-0 text-center">
                                        {loading ? <Loader /> :  <button className="btn btn-primary btn-block" type="submit"> Connexion </button>}
                                        </div>
                                        {
                                            error ? <div>
                                                <br />
                                                <span style={{ marginTop: '100px', textAlign: 'center', color: 'red' }}>Veuillez verifier vos informations</span>
                                            </div>
                                                :
                                                null
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    )
};
export default Login;