import React, { useState, useEffect } from "react";
import Dropzone from 'react-dropzone'
import Checkbox from "react-custom-checkbox";
import { useDispatch, useSelector } from "react-redux";
import { uploadFileService, getBrandListService , addProductService} from "../../actions/home/home"
import { Redirect } from "react-router-dom";

import Navbar from "../../components/navbar/navbar";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Loader from "../../components/loader/loader";

const CreateProduct = () => {

    const Dispatch = useDispatch();

    const loadingupload = useSelector(state => state.HomeReducer.loadingupload);
    const filename = useSelector(state => state.HomeReducer.filename);
    const loading = useSelector(state => state.HomeReducer.loading);
    const addproductstatus = useSelector(state => state.HomeReducer.addproductstatus);
    const token = useSelector(state => state.AuthReducer.token);
    const brandlist = useSelector((state) => state.HomeReducer.brandlist);

    const [nom, SetNom] = useState("");
    const [prix, setPrix] = useState("");
    const [prixVente, setPrixVente] = useState("");
    const [description, setDescription] = useState("");
    const [brand, setBrand] = useState("null");

    const [error, setError] = useState(false);


    useEffect(() => {
        Dispatch(getBrandListService(token))
      }, []);

    const handleFile = (file) => {
        console.log("filesss", file[0])
        Dispatch(uploadFileService(file[0]))
    }

    const handleNom = (e) => {
        SetNom(e.target.value)
        setError(false)
    }

    const handlePrix = (e) => {
        setPrix(e.target.value)
        setError(false)
    }

    const handleDescripton = (e) => {
        setDescription(e.target.value)
        setError(false)
    }


    const handlePrixVente = (e) => {
        setPrixVente(e.target.value)
        setError(false)
    }

    const handleBrand = (e) => {
        setBrand(e.target.value)
        setError(false)
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        if (nom !== "" || nom.trim() !== "" || prix !== "" || prix.trim() !== "" || description !== "" || description.trim() !== "" || prixVente !== "" || prixVente.trim() !== "" || brand !== "null" || filename !== "") {
            Dispatch(addProductService(nom, prix,1, description, Number(prixVente), filename, brand,token))
        }
        else {
            setError(true)
        }
    }


    let brandList = brandlist.map((item,index)=>{
            return(
                <option value={item.id}>{item.name}</option>
            )
    })

    let redirect = null;

    if (addproductstatus) {
        redirect = <Redirect to="/product-list" />
    }

    return (
        <>
            <div id="wrapper">
                {redirect}
                <Header />
                <Navbar />
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card-box">
                                        <h4 class="m-t-0 header-title">Ajouter un produit</h4>

                                        <form onSubmit={handleSubmit}>

                                            {
                                                loading ? <Loader />
                                                :
                                                <div class="form-row">
                                                <div class="form-group col-md-6">
                                                    <label for="inputState" class="col-form-label">Marque</label>
                                                    <select value={brand} onChange={handleBrand} class="form-control">
                                                        <option value="null">Choisir</option>
                                                        {brandList}
                                                    </select>
                                                </div>
                                            </div>
                                            }

                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                    <label for="inputEmail4" class="col-form-label">Nom de l'article</label>
                                                    <input type="text" value={nom} onChange={handleNom} class="form-control" id="inputEmail4" placeholder="Nom de l'article" />
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="inputAddress2" class="col-form-label">Description</label>
                                                    <input type="text" value={description} onChange={handleDescripton} class="form-control" placeholder="Description" />
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="inputPassword4" class="col-form-label">Prix</label>
                                                    <input type="text" value={prix} onChange={handlePrix} class="form-control" placeholder="Prix de l'article" />
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="inputPassword4" class="col-form-label">Prix de vente</label>
                                                    <input type="text" value={prixVente} onChange={handlePrixVente} class="form-control" placeholder="prix de vente" />
                                                </div>
                                            </div>
                                            {/* <div class="form-group">
                                                <label for="inputAddress" class="col-form-label">Telephone</label>
                                                <input type="text" value={telephone} onChange={handleTelephone} class="form-control" placeholder="Telephone" />
                                            </div> */}

                                            <Dropzone
                                                onDrop={acceptedFiles => handleFile(acceptedFiles)}
                                                accept='image/jpg,image/jpeg,image/png'
                                            >
                                                {({ getRootProps, getInputProps, isDragActive }) => (
                                                    <section>
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            <p style={{
                                                                background: "#f5f5f5",
                                                                border: isDragActive ? "1px dashed #71b6f9" : "1px dashed #c2c2c2",
                                                                borderRadius: "3px",
                                                                textAlign: "center",
                                                                padding: "36px",
                                                                fontSize: "12px",
                                                                fontWeight: "600"
                                                            }}>

                                                                {isDragActive ? "Uploaded" : loadingupload ? "Chargement" : filename !== "" ? filename : "Glissez ou cliquer pour importer le logo"}

                                                            </p>
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>

                                            {error ? <p style={{ color: "red" }}>Verifiez vos informations</p> : null}
                                            {
                                                loading ? <Loader /> : <button type="submit" class="btn btn-primary" style={{ marginTop: 10 }}>Ajouter</button>
                                            }

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
};
export default CreateProduct;