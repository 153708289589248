import * as actionTypes from '../../types/home/home'

const initialState = {
    loadingupload: false,
    error: false,
    filename: "",
    loading: false,
    createbrandstatus: false,
    brandlist: [],
    addproductstatus: false,
    productlist: [],

    addbanierestatus: false,
    banierelist: [],

    addpubstatus: false,
    publist: []
}

const ReducerHome = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.UPLOAD_FILE_START:
            return {
                ...state,
                loadingupload: true,
                error: null
            }

        case actionTypes.UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                loadingupload: false,
                error: false,
                filename: action.filename
            }

        case actionTypes.UPLOAD_FILE_FAIL:
            return {
                ...state,
                error: action.error,
                loadingupload: false
            }

        case actionTypes.CREATE_BRAND_START:
            return {
                ...state,
                loading: true,
                error: false
            }

        case actionTypes.CREATE_BRAND_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                createbrandstatus: true
            }

        case actionTypes.CREATE_BRAND_FAIL:
            return {
                ...state,
                error: action.error,
                loading: false,
            }

        case actionTypes.GET_BRAND_LIST_START:
            return {
                ...state,
                loading: true,
                error: false,
                createbrandstatus: false,
                filename: ""
            }

        case actionTypes.GET_BRAND_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                brandlist: action.brandlist

            }

        case actionTypes.GET_BRAND_LIST_FAIL:
            return {
                ...state,
                error: action.error,
                loading: false,
            }

        case actionTypes.ADD_PRODUCT_START:
            return {
                ...state,
                loading: true,
                error: false
            }

        case actionTypes.ADD_PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                addproductstatus: true
            }

        case actionTypes.ADD_PRODUCT_FAIL:
            return {
                ...state,
                error: action.error,
                loading: false,
            }

        case actionTypes.GET_PRODUCT_LIST_START:
            return {
                ...state,
                loading: true,
                error: false,
                addproductstatus: false,
                filename: ""
            }

        case actionTypes.GET_PRODUCT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                productlist: action.productlist
            }

        case actionTypes.GET_PRODUCT_LIST_FAIL:
            return {
                ...state,
                error: action.error,
                loading: false,
            }

        case actionTypes.ADD_BANIERE_START:
            return {
                ...state,
                loading: true,
                error: false
            }

        case actionTypes.ADD_BANIERE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                addbanierestatus: true
            }

        case actionTypes.ADD_BANIERE_FAIL:
            return {
                ...state,
                error: action.error,
                loading: false,
            }

        case actionTypes.GET_BANIERE_LIST_START:
            return {
                ...state,
                loading: true,
                error: false,
                addbanierestatus: false,
                filename: ""
            }

        case actionTypes.GET_BANIERE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                banierelist: action.banierelist
            }

        case actionTypes.GET_BANIERE_LIST_FAIL:
            return {
                ...state,
                error: action.error,
                loading: false,
            }

        case actionTypes.CHANGE_BANIERE_STATUS_START:
            return {
                ...state,
                loading: true,
                error: false
            }

        case actionTypes.CHANGE_BANIERE_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
            }

        case actionTypes.CHANGE_BANIERE_STATUS_FAIL:
            return {
                ...state,
                error: action.error,
                loading: false,
            }

        case actionTypes.ADD_PUB_START:
            return {
                ...state,
                loading: true,
                error: false
            }

        case actionTypes.ADD_PUB_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                addpubstatus: true
            }

        case actionTypes.ADD_PUB_FAIL:
            return {
                ...state,
                error: action.error,
                loading: false,
            }

        case actionTypes.GET_PUB_LIST_START:
            return {
                ...state,
                loading: true,
                error: false,
                addpubstatus: false,
                filename: ""
            }

        case actionTypes.GET_PUB_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                publist: action.publist
            }

        case actionTypes.GET_PUB_LIST_FAIL:
            return {
                ...state,
                error: action.error,
                loading: false,
            }

        case actionTypes.CHANGE_PUB_STATUS_START:
            return {
                ...state,
                loading: true,
                error: false
            }

        case actionTypes.CHANGE_PUB_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
            }

        case actionTypes.CHANGE_PUB_STATUS_FAIL:
            return {
                ...state,
                error: action.error,
                loading: false,
            }

        case actionTypes.CHANGE_SHOW_STATUS_START:
            return {
                ...state,
                loading: true,
                error: false
            }

        case actionTypes.CHANGE_SHOW_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
            }

        case actionTypes.CHANGE_SHOW_STATUS_FAIL:
            return {
                ...state,
                error: action.error,
                loading: false,
            }

        case actionTypes.CHANGE_POPULAIRE_STATUS_START:
            return {
                ...state,
                loading: true,
                error: false
            }

        case actionTypes.CHANGE_POPULAIRE_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
            }

        case actionTypes.CHANGE_POPULAIRE_STATUS_FAIL:
            return {
                ...state,
                error: action.error,
                loading: false,
            }

        case actionTypes.CHANGE_PRODUCT_POPULAIRE_STATUS_START:
            return {
                ...state,
                loading: true,
                error: false
            }

        case actionTypes.CHANGE_PRODUCT_POPULAIRE_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
            }

        case actionTypes.CHANGE_PRODUCT_POPULAIRE_STATUS_FAIL:
            return {
                ...state,
                error: action.error,
                loading: false,
            }

        case actionTypes.CHANGE_PRODUCT_PLUS_REGARDES_STATUS_START:
            return {
                ...state,
                loading: true,
                error: false
            }

        case actionTypes.CHANGE_PRODUCT_PLUS_REGARDES_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
            }

        case actionTypes.CHANGE_PRODUCT_PLUS_REGARDES_STATUS_FAIL:
            return {
                ...state,
                error: action.error,
                loading: false,
            }

        case actionTypes.CHANGE_PRODUCT_NOUVEAUTES_STATUS_START:
            return {
                ...state,
                loading: true,
                error: false
            }

        case actionTypes.CHANGE_PRODUCT_NOUVEAUTES_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
            }

        case actionTypes.CHANGE_PRODUCT_NOUVEAUTES_STATUS_FAIL:
            return {
                ...state,
                error: action.error,
                loading: false,
            }

        case actionTypes.CHANGE_PRODUCT_SHOW_STATUS_START:
            return {
                ...state,
                loading: true,
                error: false
            }

        case actionTypes.CHANGE_PRODUCT_SHOW_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
            }

        case actionTypes.CHANGE_PRODUCT_SHOW_STATUS_FAIL:
            return {
                ...state,
                error: action.error,
                loading: false,
            }

        default:
            return state
    }
}

export default ReducerHome