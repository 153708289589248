import React, { useState, useEffect } from "react";
import Dropzone from 'react-dropzone'
import Checkbox from "react-custom-checkbox";
import { useDispatch, useSelector } from "react-redux";
import { uploadFileService, addPubService} from "../../actions/home/home"
import { Redirect } from "react-router-dom";

import Navbar from "../../components/navbar/navbar";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Loader from "../../components/loader/loader";

const AddPub = () => {

    const Dispatch = useDispatch();

    const loadingupload = useSelector(state => state.HomeReducer.loadingupload);
    const filename = useSelector(state => state.HomeReducer.filename);
    const loading = useSelector(state => state.HomeReducer.loading);
    const addpubstatus = useSelector(state => state.HomeReducer.addpubstatus);
    const token = useSelector(state => state.AuthReducer.token);

    const [error, setError] = useState(false);

    const handleFile = (file) => {
        console.log("filesss", file[0])
        Dispatch(uploadFileService(file[0]))
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        if (filename !== "") {
            Dispatch(addPubService(filename,token))
        }
        else {
            setError(true)
        }
    }

    let redirect = null;

    if (addpubstatus) {
        redirect = <Redirect to="/pub-list" />
    }

    return (
        <>
            <div id="wrapper">
                {redirect}
                <Header />
                <Navbar />
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card-box">
                                        <h4 class="m-t-0 header-title">Ajouter une pub</h4>

                                        <form onSubmit={handleSubmit}>

                                            <Dropzone
                                                onDrop={acceptedFiles => handleFile(acceptedFiles)}
                                                accept='image/jpg,image/jpeg,image/png'
                                            >
                                                {({ getRootProps, getInputProps, isDragActive }) => (
                                                    <section>
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            <p style={{
                                                                background: "#f5f5f5",
                                                                border: isDragActive ? "1px dashed #71b6f9" : "1px dashed #c2c2c2",
                                                                borderRadius: "3px",
                                                                textAlign: "center",
                                                                padding: "36px",
                                                                fontSize: "12px",
                                                                fontWeight: "600"
                                                            }}>

                                                                {isDragActive ? "Uploaded" : loadingupload ? "Chargement" : filename !== "" ? filename : "Glissez ou cliquer pour importer la baniere"}

                                                            </p>
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>

                                            {error ? <p style={{ color: "red" }}>Verifiez vos informations</p> : null}
                                            {
                                                loading ? <Loader /> : <button type="submit" class="btn btn-primary" style={{ marginTop: 10 }}>Ajouter</button>
                                            }

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
};
export default AddPub;