
import React, { useEffect } from 'react'

//IMPORT CONFIG & DEPENDENCIES
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginCheckState } from './actions/auth/auth';


import Login from "./pages/authentication/login";
import Panel from "./pages/home/panel";
import CreateBrand from './pages/marques/creermarque';
import BrandList from './pages/marques/brandlist';
import CreateProduct from './pages/produits/creerproduit';
import ProductList from './pages/produits/productlist';
import AddBaniere from './pages/baniere/addbaniere';
import BaniereList from './pages/baniere/banierlist';
import AddPub from './pages/pub/addpub';
import PubList from './pages/pub/publist';

const App = () => {

  const dispatch = useDispatch();

  const token = useSelector(state => state.AuthReducer.token);
  const role = useSelector(state => state.AuthReducer.role);

  useEffect(() => {
    dispatch(loginCheckState());
  }, []);

  let route = (
    <Switch>
      <Route path="/login" component={Login} />
      <Redirect to='/login' />
    </Switch>
  )

  if (token && (role === 'admin')) {
    route = (
      <Switch>
        <Route path="/pub-list" component={PubList} />
        <Route path="/add-pub" component={AddPub} />
        <Route path="/baniere-list" component={BaniereList} />
        <Route path="/add-baniere" component={AddBaniere} />
        <Route path="/product-list" component={ProductList} />
        <Route path="/create-product" component={CreateProduct} />
        <Route path="/brand-list" component={BrandList} />
        <Route path="/create-marque" component={CreateBrand} />
        <Route path="/" exact component={Panel} />
        <Redirect to='/' />
      </Switch>
    )
  }

  return (
    <div>
      {route}
    </div>
  );
}

export default withRouter(App);
