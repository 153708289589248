export const UPLOAD_FILE_START = "UPLOAD_FILE_START";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAIL = "UPLOAD_FILE_FAIL";

export const CREATE_BRAND_START = "CREATE_BRAND_START";
export const CREATE_BRAND_SUCCESS = "CREATE_BRAND_SUCCESS";
export const CREATE_BRAND_FAIL = "CREATE_BRAND_FAIL";

export const GET_BRAND_LIST_START = "GET_BRAND_LIST_START";
export const GET_BRAND_LIST_SUCCESS = "GET_BRAND_LIST_SUCCESS";
export const GET_BRAND_LIST_FAIL = "GET_BRAND_LIST_FAIL";

export const ADD_PRODUCT_START = "ADD_PRODUCT_START";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAIL = "ADD_PRODUCT_FAIL";

export const GET_PRODUCT_LIST_START = "GET_PRODUCT_LIST_START";
export const GET_PRODUCT_LIST_SUCCESS = "GET_PRODUCT_LIST_SUCCESS";
export const GET_PRODUCT_LIST_FAIL = "GET_PRODUCT_LIST_FAIL";

export const ADD_BANIERE_START = "ADD_BANIERE_START";
export const ADD_BANIERE_SUCCESS = "ADD_BANIERE_SUCCESS";
export const ADD_BANIERE_FAIL = "ADD_BANIERE_FAIL";

export const GET_BANIERE_LIST_START = "GET_BANIERE_LIST_START";
export const GET_BANIERE_LIST_SUCCESS = "GET_BANIERE_LIST_SUCCESS";
export const GET_BANIERE_LIST_FAIL = "GET_BANIERE_LIST_FAIL";

export const CHANGE_BANIERE_STATUS_START = "CHANGE_BANIERE_STATUS_START";
export const CHANGE_BANIERE_STATUS_SUCCESS = "CHANGE_BANIERE_STATUS_SUCCESS";
export const CHANGE_BANIERE_STATUS_FAIL = "CHANGE_BANIERE_STATUS_FAIL";

export const ADD_PUB_START = "ADD_PUB_START";
export const ADD_PUB_SUCCESS = "ADD_PUB_SUCCESS";
export const ADD_PUB_FAIL = "ADD_PUB_FAIL";

export const GET_PUB_LIST_START = "GET_PUB_LIST_START";
export const GET_PUB_LIST_SUCCESS = "GET_PUB_LIST_SUCCESS";
export const GET_PUB_LIST_FAIL = "GET_PUB_LIST_FAIL";

export const CHANGE_PUB_STATUS_START = "CHANGE_PUB_STATUS_START";
export const CHANGE_PUB_STATUS_SUCCESS = "CHANGE_PUB_STATUS_SUCCESS";
export const CHANGE_PUB_STATUS_FAIL = "CHANGE_PUB_STATUS_FAIL";

export const CHANGE_SHOW_STATUS_START = "CHANGE_SHOW_STATUS_START";
export const CHANGE_SHOW_STATUS_SUCCESS = "CHANGE_SHOW_STATUS_SUCCESS";
export const CHANGE_SHOW_STATUS_FAIL = "CHANGE_SHOW_STATUS_FAIL";

export const CHANGE_POPULAIRE_STATUS_START = "CHANGE_POPULAIRE_STATUS_START";
export const CHANGE_POPULAIRE_STATUS_SUCCESS = "CHANGE_POPULAIRE_STATUS_SUCCESS";
export const CHANGE_POPULAIRE_STATUS_FAIL = "CHANGE_POPULAIRE_STATUS_FAIL";

export const CHANGE_PRODUCT_POPULAIRE_STATUS_START = "CHANGE_PRODUCT_POPULAIRE_STATUS_START";
export const CHANGE_PRODUCT_POPULAIRE_STATUS_SUCCESS = "CHANGE_PRODUCT_POPULAIRE_STATUS_SUCCESS";
export const CHANGE_PRODUCT_POPULAIRE_STATUS_FAIL = "CHANGE_PRODUCT_POPULAIRE_STATUS_FAIL";

export const CHANGE_PRODUCT_PLUS_REGARDES_STATUS_START = "CHANGE_PRODUCT_PLUS_REGARDES_STATUS_START";
export const CHANGE_PRODUCT_PLUS_REGARDES_STATUS_SUCCESS = "CHANGE_PRODUCT_PLUS_REGARDES_STATUS_SUCCESS";
export const CHANGE_PRODUCT_PLUS_REGARDES_STATUS_FAIL = "CHANGE_PRODUCT_PLUS_REGARDES_STATUS_FAIL";

export const CHANGE_PRODUCT_NOUVEAUTES_STATUS_START = "CHANGE_PRODUCT_NOUVEAUTES_STATUS_START";
export const CHANGE_PRODUCT_NOUVEAUTES_STATUS_SUCCESS = "CHANGE_PRODUCT_NOUVEAUTES_STATUS_SUCCESS";
export const CHANGE_PRODUCT_NOUVEAUTES_STATUS_FAIL = "CHANGE_PRODUCT_NOUVEAUTES_STATUS_FAIL";

export const CHANGE_PRODUCT_SHOW_STATUS_START = "CHANGE_PRODUCT_SHOW_STATUS_START";
export const CHANGE_PRODUCT_SHOW_STATUS_SUCCESS = "CHANGE_PRODUCT_SHOW_STATUS_SUCCESS";
export const CHANGE_PRODUCT_SHOW_STATUS_FAIL = "CHANGE_PRODUCT_SHOW_STATUS_FAIL";


