import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import Switch from "react-switch";

import Navbar from "../../components/navbar/navbar";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Loader from "../../components/loader/loader";


import { getBrandListService , changePopulaireStatusService, changeShowStatusService} from "../../actions/home/home";

const BrandList = () => {

  const token = useSelector((state) => state.AuthReducer.token);

  const Dispacth = useDispatch();

  useEffect(() => {
    Dispacth(getBrandListService(token))
  }, []);

  const handleOnShow = (switchstatus, item) => {
    Dispacth(changeShowStatusService(item.id, !switchstatus, token))
  }

  const handlePopulaire = (switchstatus, item) => {
    Dispacth(changePopulaireStatusService(item.id, !switchstatus, token))
  }


  const brandlist = useSelector((state) => state.HomeReducer.brandlist);

  const loading = useSelector((state) => state.HomeReducer.loading);


  let brandList =  brandlist.map((item ,index ) => {
    return (
      <tr key={index}>
        <td>{index + 1}
        </td>
        <td>
           <img src={item.logo ? item.logo : ""} className="small-logo-list" style={{width:40, height:40, borderRadius:50}} />
        </td>
        <td>{item.name}</td>
        <td>{item.proprietaire}</td>
        <td> <Switch onChange={() => handlePopulaire(item.populaire, item)} checked={item.populaire} /> </td>
        <td>{item.telephone}</td>
        <td> <Switch onChange={() => handleOnShow(item.show, item)} checked={item.show} /> </td>
        <td>{item.description}</td>
        <td>{item.type ===1 ? "Hommes" : item.type ===2 ? "Femmes" : item.type ===3 ? "Enfants" : item.type === 4 ? "Accessoires" : item.type === 5 ? "Tout" : "Error"}</td>
        <td>
          <ul class="list-inline applicant-list-actions">

            <li class="list-inline-item">
              <a href="#" class="text-muted">
                <i 
                 onClick={() => {
                //   openViewTransporteur(item);
                }}
                class="mdi mdi-eye btn-styled"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a href="#" class="text-muted">
                <i 
                  onClick={() => {
                    // openEditTransporteur(item);
                  }}

                class="mdi mdi-border-color btn-styled"></i>
              </a>
            </li>

            <li class="list-inline-item">
              <a class="text-custom">
                <i 
                   onClick={(item) => {
                    // openConfirmDelete(item);
                  }}
                class="mdi mdi-delete btn-styled"></i>
              </a>
            </li>
           

          </ul>
        </td>
      </tr>
    );
  });


  return (
    <>
      <div id="wrapper">
        <Header />
        <Navbar />
        <div class="content-page">
          <div class="content">
          <div class="input-group-append btn-go-back">
                            <Link to="/">
                                <button class="btn" type="submit">
                                    <i class="mdi mdi-arrow-left"></i>
                                </button>
                            </Link>
                        </div>

            <div class="input-group-append btn-go-back">
              <Link to="/">
                <button class="btn" type="submit">
                  <i class="mdi mdi-arrow-left"></i>
                </button>
              </Link>
            </div>

            <div class="container-fluid">
              <div className="table-responsive">
                <table className="table table-hover mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Logo</th>
                      <th>Marque</th>
                      <th>Proprietaire</th>
                      <th>Populaire</th>
                      <th>Telephone</th>
                      <th>status</th>
                      <th>Description</th>
                      <th>Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className="set-relative">
                    {loading ? (
                      <div className="loader-parent height-full">
                        <Loader />
                      </div>
                    ) : (
                      brandList
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BrandList;
